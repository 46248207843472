const simpleStringHash = (s) => {
    return Math.abs(s.split('').reduce((hash, c) => {
        const charCode = `${c}`.charCodeAt(0);
        let newHash = (hash << 5) - hash + charCode;
        return (newHash |= 0);
    }, 0));
};
export const rgbFromRandomString = (val) => {
    const n = simpleStringHash(val);
    const r = (n * 1223) % 256;
    const g = (n * 2069) % 256;
    const b = (n * 977) % 256;
    return `rgb(${r}, ${g}, ${b})`;
};
const colors = {
    base: {
        black: '#000000',
        white: '#FFFFFF',
    },
    primary: {
        orange: {
            100: '#FEF3EA',
            200: '#FBDABD',
            300: '#F8B57C',
            500: '#F6862A',
        },
        blue: {
            100: '#E5EBEF',
            200: '#B5C4CE',
            300: '#6C899E',
            500: '#003B5D',
        },
    },
    secondary: {
        dark: {
            100: '#EAEBEA',
            200: '#C0C2C1',
            300: '#818584',
            500: '#2E3532',
        },
        light: {
            100: '#FBFBFB',
            200: '#F2F3F4',
            300: '#E6E7E9',
            500: '#D6D7DB',
        },
        yellow: {
            100: '#FFFBEF',
            200: '#FEF4CE',
            300: '#FEE99E',
            500: '#FEDB5E',
        },
    },
    ui: {
        danger: '#EC2D30',
        warning: '#FEAC0E',
        success: '#0C9D61',
        info: '#3A70E2',
    },
    unbranded: {
        red: {
            500: '#A70000',
        },
        pink: {
            100: '#F5ADE5',
            300: '#FF8888',
            500: '#B5736A',
        },
        yellow: {
            100: '#F1FF9C',
            500: '#AB8F00',
        },
        brown: {
            500: '#583500',
        },
        green: {
            100: '#9FD863',
            500: '#019D07',
            900: '#004732',
        },
        blue: {
            100: '#5DEEE6',
            300: '#28979E',
            500: '#006DBA',
            900: '#090FA3',
        },
        purple: {
            300: '#AF25D1',
            500: '#4F2E84',
        },
        neutral: {
            100: '#ECDCDD',
        },
    },
};
export default colors;
