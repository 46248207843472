import { faker } from '@faker-js/faker';
import { IVerticalMarketNames, } from '../models/Organization/OrganizationInterfaces';
export class OrganizationSummaryResponseBuilder {
    constructor(overrides) {
        this.build = () => {
            return Object.assign({ organizationIdentity: faker.number.int({
                    min: undefined,
                    max: undefined,
                }), name: faker.lorem.slug(1), primaryContact: {
                    userId: faker.lorem.slug(1),
                    userIdentity: faker.number.int({
                        min: undefined,
                        max: undefined,
                    }),
                    firstName: faker.lorem.slug(1),
                    lastName: faker.lorem.slug(1),
                    emailAddress: faker.lorem.slug(1),
                }, apps: [] }, this.overrides);
        };
        this.overrides = overrides;
    }
}
export class OrganizationDetailsResponseBuilder {
    constructor(overrides) {
        this.build = () => {
            const siteBuilder = new SiteResponseBuilder({});
            return Object.assign({ organizationIdentity: faker.number.int(), name: faker.lorem.slug(1), businessUnit: faker.lorem.slug(1), companyWebsite: faker.internet.url(), logo: {
                    logoFileName: faker.lorem.slug(1),
                    logoUrl: faker.internet.url(),
                }, verticalMarket: faker.helpers.enumValue(IVerticalMarketNames), primaryContact: {
                    userId: faker.lorem.slug(1),
                    userIdentity: faker.number.int({
                        min: undefined,
                        max: undefined,
                    }),
                    firstName: faker.lorem.slug(1),
                    lastName: faker.lorem.slug(1),
                    emailAddress: faker.lorem.slug(1),
                }, apps: [], userCount: faker.number.int(), sites: [siteBuilder.build()] }, this.overrides);
        };
        this.overrides = overrides;
    }
}
export class IOrganizationSnapshotBuilder {
    constructor(overrides) {
        this.build = () => {
            return Object.assign({ identity: faker.number.int({
                    min: undefined,
                    max: undefined,
                }), name: faker.lorem.slug(1) }, this.overrides);
        };
        this.overrides = overrides;
    }
}
// Sites
export class SiteResponseBuilder {
    constructor(overrides) {
        this.build = () => {
            const facilityBuilder = new FacilityResponseBuilder({});
            return Object.assign({ siteId: faker.lorem.slug(1), siteIdentity: faker.number.int(), name: faker.lorem.slug(1), address1: faker.location.streetAddress(), address2: null, city: faker.location.city(), stateOrProvince: faker.location.state(), postalCode: faker.location.zipCode(), facilities: [facilityBuilder.build()] }, this.overrides);
        };
        this.overrides = overrides;
    }
}
export class FacilityResponseBuilder {
    constructor(overrides) {
        this.build = () => {
            const facilityAdditionBuilder = new FacilityAdditionResponseBuilder({});
            return Object.assign({ facilityId: faker.lorem.slug(1), facilityIdentity: faker.number.int(), name: faker.lorem.slug(1), address1: faker.location.streetAddress(), address2: null, city: faker.location.city(), stateOrProvince: faker.location.state(), postalCode: faker.location.zipCode(), latitude: faker.location.latitude().toString(), longitude: faker.location.longitude().toString(), yearBuilt: faker.date.past().getFullYear(), originalSquareFootage: faker.number.int(), note: faker.lorem.sentence(), numberOfOccupants: faker.number.int(), primaryFacilityType: faker.lorem.slug(1), secondaryFacilityType: faker.lorem.slug(1), tertiaryFacilityType: faker.lorem.slug(1), keyCount: faker.number.int(), unitCount: faker.number.int(), customerInternalId: faker.lorem.slug(1), propertyManager: faker.person.fullName(), hasCompletedAudit: faker.datatype.boolean(), hasPropertySurvey: faker.datatype.boolean(), facilityGrouper1: faker.lorem.slug(1), facilityGrouper2: faker.lorem.slug(1), additions: [facilityAdditionBuilder.build()] }, this.overrides);
        };
        this.overrides = overrides;
    }
}
export class FacilityAdditionResponseBuilder {
    constructor(overrides) {
        this.build = () => {
            return Object.assign({ additionId: faker.lorem.slug(1), additionIdentity: faker.number.int(), name: faker.lorem.slug(1), yearBuilt: faker.date.past().getFullYear(), squareFootage: faker.number.int() }, this.overrides);
        };
        this.overrides = overrides;
    }
}
export class ConfigurationResponseBuilder {
    constructor(overrides) {
        this.build = () => {
            return Object.assign({ id: faker.lorem.slug(1), identity: faker.number.int() }, this.overrides);
        };
        this.overrides = overrides;
    }
}
