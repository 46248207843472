import { useEffect, useState } from 'react';
import { useOrganization } from '../../hooks//Organization/OrganizationHook';
import { IAppNames } from '../../models/App';
import { useOrganizationSummaries } from '../Organization/OrganizationSummaryContext';
import { useCurrentUser } from '../User/UserContext';
import { canViewFacility, hasAppAccess, isSystemAdmin } from './Roles';
/** Can only be used within User Context */
export const useAdminAccess = () => {
    const { user, status: { isLoading }, } = useCurrentUser();
    return {
        isLoading,
        hasAccess: isSystemAdmin(user === null || user === void 0 ? void 0 : user.systemRole),
    };
};
// should probably live as accessor on OrganizationSummariesProvider
export const getOrgApps = (organizations, selectedOrganizationIdentity) => {
    if (!selectedOrganizationIdentity) {
        return [];
    }
    const selectedOrganization = organizations.find((org) => org.identity === selectedOrganizationIdentity);
    return (selectedOrganization === null || selectedOrganization === void 0 ? void 0 : selectedOrganization.apps) || [];
};
/** Can only be used within User and Required Org page */
export const useAppAccess = (app) => {
    const { user, status: { isLoading: isUserLoading }, } = useCurrentUser();
    const { selectedOrganizationIdentity, isLoading: isOrgSummaryLoading, organizations, } = useOrganizationSummaries();
    const { isLoading: isOrgDetailsLoading } = useOrganization({
        organizationIdentity: selectedOrganizationIdentity,
    });
    // should probably live as accessor on OrganizationSummariesProvider
    const orgApps = getOrgApps(organizations, selectedOrganizationIdentity);
    const isOrgLoading = isOrgSummaryLoading ||
        !selectedOrganizationIdentity ||
        isOrgDetailsLoading;
    const [hasAccess, setHasAccess] = useState(false);
    useEffect(() => {
        if (!isUserLoading && !isOrgLoading) {
            setHasAccess(hasAppAccess(user, orgApps, selectedOrganizationIdentity, app));
        }
    }, [
        isUserLoading,
        isOrgLoading,
        selectedOrganizationIdentity,
        organizations,
    ]);
    return {
        isLoading: isUserLoading || isOrgLoading,
        hasAccess: hasAccess,
    };
};
/** For user management purposes, not intended for logged in user*/
export const useAllOrgAppAccess = (user, organizationIdentity, orgApps) => {
    const hasAccess = (maybe) => {
        return hasAppAccess(user, orgApps, organizationIdentity, maybe);
    };
    return {
        [IAppNames.REPORTS]: hasAccess(IAppNames.REPORTS),
        [IAppNames.FCA]: hasAccess(IAppNames.FCA),
        [IAppNames.PLANNER]: hasAccess(IAppNames.PLANNER),
        [IAppNames.PIQ]: hasAccess(IAppNames.PIQ),
    };
};
/** Can only be used within User, Required Org page, and Required Facility page */
export const useFacilityAccess = (facilityIdentity) => {
    const { user, status: { isLoading: isUserLoading }, } = useCurrentUser();
    const { selectedOrganizationIdentity: organizationIdentity } = useOrganizationSummaries();
    const { isLoading: isOrgDetailsLoading } = useOrganization({
        organizationIdentity,
    });
    const isOrgLoading = !organizationIdentity || isOrgDetailsLoading;
    const [hasAccess, setHasAccess] = useState(false);
    useEffect(() => {
        if (!isUserLoading && !isOrgLoading && user !== undefined) {
            setHasAccess(canViewFacility(user, organizationIdentity, facilityIdentity));
        }
    }, [isUserLoading, isOrgLoading, user]);
    return {
        isLoading: isUserLoading || isOrgLoading,
        hasAccess: hasAccess,
    };
};
