const space = {
    px: '1px',
    0.5: '0.15625rem',
    1: '0.3125rem',
    1.5: '0.46875rem',
    2: '0.625rem',
    2.5: '0.78125rem',
    3: '0.9375rem',
    3.5: '1.09375rem',
    4: '1.25rem',
    5: '1.5625rem',
    6: '1.875rem',
    7: '2.1875rem',
    8: '2.5rem',
    9: '2.8125rem',
    10: '3.125rem',
    11: '3.4375rem',
    12: '3.75rem',
    14: '4.375rem',
    16: '5rem',
    20: '6.25rem',
    24: '7.5rem',
    28: '8.75rem',
    32: '10rem',
    36: '11.25rem',
    40: '12.5rem',
    44: '13.75rem',
    48: '15rem',
    52: '16.25rem',
    56: '17.5rem',
    60: '18.75rem',
    64: '20rem',
    72: '22.5rem',
    80: '25rem',
    96: '30rem',
};
export default space;
