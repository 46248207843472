import { jsx as _jsx } from "react/jsx-runtime";
import { Box } from '@chakra-ui/react';
import React from 'react';
import Chart from 'react-apexcharts';
export const BarChart = ({ config, containerHeight, containerWidth, data, }) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0;
    const hasGroups = data.datasets.length > 1;
    const options = {
        chart: {
            id: 'basic-bar',
            group: config.chartGroup ? config.chartGroup : '',
            stacked: config.stacked ? true : false,
            toolbar: {
                show: false,
            },
        },
        colors: config.datasetColors,
        dataLabels: {
            enabled: false,
            offsetX: config.horizontal ? -10 : 0,
            offsetY: config.horizontal ? 0 : 10,
            style: {
                colors: ['black'],
            },
            formatter: ((_a = config.barLabel) === null || _a === void 0 ? void 0 : _a.format)
                ? (_b = config.barLabel) === null || _b === void 0 ? void 0 : _b.format
                : (val) => val || '',
        },
        legend: {
            // click/hover events of the legend will stop working if you provide custom legend labels
            customLegendItems: ((_c = config.legend) === null || _c === void 0 ? void 0 : _c.datasetLabels)
                ? config.legend.datasetLabels
                : [],
            position: ((_d = config.legend) === null || _d === void 0 ? void 0 : _d.position) || 'bottom',
        },
        plotOptions: {
            bar: {
                // distributed true allows bars to be formatted individually
                // we only want to format bars individually when we are not grouping or stacking datasets
                distributed: data.datasets.length > 1 ? false : true,
                horizontal: config.horizontal ? true : false,
                dataLabels: {
                    position: ((_e = config.barLabel) === null || _e === void 0 ? void 0 : _e.position) || 'top',
                    total: {
                        enabled: (_g = (_f = config.barLabel) === null || _f === void 0 ? void 0 : _f.enabled) !== null && _g !== void 0 ? _g : hasGroups,
                        offsetY: config.horizontal ? 0 : 15,
                        offsetX: config.horizontal ? -25 : 0,
                        formatter: ((_h = config.barLabel) === null || _h === void 0 ? void 0 : _h.format)
                            ? (_j = config.barLabel) === null || _j === void 0 ? void 0 : _j.format
                            : (val) => val || '',
                    },
                },
            },
        },
        annotations: config.showAvg
            ? config.annotations
            : { xaxis: [], yaxis: [], points: [], images: [] },
        tooltip: {
            enabled: ((_k = config.tooltip) === null || _k === void 0 ? void 0 : _k.enabled) ? true : false,
            shared: (_m = (_l = config.tooltip) === null || _l === void 0 ? void 0 : _l.shared) !== null && _m !== void 0 ? _m : true,
            intersect: (_p = (_o = config.tooltip) === null || _o === void 0 ? void 0 : _o.intersect) !== null && _p !== void 0 ? _p : false,
            custom: (_q = config.tooltip) === null || _q === void 0 ? void 0 : _q.custom,
            x: {
                formatter: (_s = (_r = config.formatters) === null || _r === void 0 ? void 0 : _r.tooltip) === null || _s === void 0 ? void 0 : _s.x,
            },
            y: {
                formatter: (_u = (_t = config.formatters) === null || _t === void 0 ? void 0 : _t.tooltip) === null || _u === void 0 ? void 0 : _u.y,
            },
        },
        xaxis: {
            categories: data.axisCategories,
            labels: {
                style: { colors: (_v = config.labelColors) === null || _v === void 0 ? void 0 : _v.xAxis },
                formatter: (_w = config.formatters) === null || _w === void 0 ? void 0 : _w.xAxis,
                rotate: 0,
                trim: true,
                hideOverlappingLabels: false,
            },
        },
        yaxis: {
            min: (_x = config.formatters) === null || _x === void 0 ? void 0 : _x.min,
            max: (_y = config.formatters) === null || _y === void 0 ? void 0 : _y.max,
            labels: {
                style: { colors: (_z = config.labelColors) === null || _z === void 0 ? void 0 : _z.yAxis },
                formatter: (_0 = config.formatters) === null || _0 === void 0 ? void 0 : _0.yAxis,
            },
        },
    };
    const series = data.datasets;
    return (_jsx(Box, Object.assign({ height: containerHeight, width: containerWidth }, { children: _jsx(Chart, { options: options, series: series, type: 'bar', height: containerHeight, width: containerWidth }) })));
};
