export class OrganizationService {
    constructor(httpRequest) {
        this.httpRequest = httpRequest;
    }
    /**
     * Adds or updates an organization.
     * @param formData
     * @returns OrganizationDetailResponse OK
     * @throws ApiError
     */
    postApiV1OrganizationConfigure(formData) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v1/Organization/configure',
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                400: `Bad Request`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }
    /**
     * @deprecated
     * Adds or updates a new site.
     * @param requestBody The details of the site
     * @returns ConfigurationResponse OK
     * @throws ApiError
     */
    postApiV1OrganizationConfigureSite(requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v1/Organization/configure/site',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }
    /**
     * @deprecated
     * Adds or updates a new facility.
     * @param requestBody The details of the facility
     * @returns ConfigurationResponse OK
     * @throws ApiError
     */
    postApiV1OrganizationConfigureFacility(requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v1/Organization/configure/facility',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }
    /**
     * Gets the summary list of organizations a user has access to.
     * @param appContext The name of an app context to limit the result to
     * @returns OrganizationSummaryResponse OK
     * @throws ApiError
     */
    getApiV1OrganizationList(appContext) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/Organization/list',
            query: {
                appContext: appContext,
            },
        });
    }
    /**
     * Gets presentation details about a single organization.
     * @param organizationId The ID of the organization to detail
     * @returns OrganizationDetailResponse OK
     * @throws ApiError
     */
    getApiV1OrganizationView(organizationId) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/Organization/view/{organizationId}',
            path: {
                organizationId: organizationId,
            },
            errors: {
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }
    /**
     * Gets presentation details about a single organization.
     * @param organizationId The ID of the organization to detail
     * @returns OrganizationDetailResponse OK
     * @throws ApiError
     */
    getApiV1OrganizationLoad(organizationId) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/Organization/load/{organizationId}',
            path: {
                organizationId: organizationId,
            },
            errors: {
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }
    /**
     * Archives or un-archives organization(s).
     * @param requestBody The request detailing the organizations to (un-)archive
     * @returns any OK
     * @throws ApiError
     */
    postApiV1OrganizationArchive(requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v1/Organization/archive',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }
    /**
     * Returns system data needed to create or update an organization
     * @returns OrganizationTemplateResponse OK
     * @throws ApiError
     */
    getApiV1OrganizationInitialize() {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/Organization/initialize',
            errors: {
                403: `Forbidden`,
            },
        });
    }
}
