import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { UserContext } from './UserContext';
import { useUser } from './UserDataHook';
export function UserProvider({ children }) {
    const nonActiveUserStatus = 412; //api error status when the user is either deleted or inactive.
    const { user, updateProfile, isLoading, activateUser } = useUser(nonActiveUserStatus);
    return (_jsx(UserContext.Provider, Object.assign({ value: {
            user,
            updateUserProfile: updateProfile,
            activateUser: activateUser,
            status: {
                isActive: (user === null || user === void 0 ? void 0 : user.isActive) || false,
                isEulaAccepted: (user === null || user === void 0 ? void 0 : user.isEulaAccepted) || false,
                isLoading: isLoading || false,
            },
        } }, { children: children })));
}
export function domainOrgSummary(orgs) {
    return orgs.map((org) => {
        var _a;
        if (!org.organizationIdentity) {
            console.error('Organization identity is missing', org);
            throw new Error('Organization identity is missing');
        }
        return {
            identity: org.organizationIdentity,
            name: (_a = org.name) !== null && _a !== void 0 ? _a : 'Unknown organization name',
        };
    });
}
