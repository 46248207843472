var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useEffect, useState } from 'react';
import { useDependencies } from '../../contexts/Dependencies/DependenciesContext';
import { adjustPropertySize, convertIUpdateFacilityToIFacility, convertOrganizationFormToIUpsertOrganization, resolvePropertySize, } from '../../models/Organization/OrganizationConversions';
import { useOrganizationService } from '../../services/OrganizationService';
export const useOrganization = ({ organizationIdentity, onUpdateOrganization, onArchiveOrganization, }) => {
    const { platformApi } = useDependencies();
    const { organization } = useOrganizationService({ platformApi });
    const [isLoading, setIsLoading] = useState(false);
    const [isTemplateLoading, setIsTemplateLoading] = useState(false);
    const [organizationDetails, setOrganizationDetails] = useState(undefined);
    const [orgTemplate, setOrgTemplate] = useState({
        verticalMarkets: [],
        appContexts: [],
    });
    useEffect(() => {
        if (!organizationIdentity)
            return;
        fetchDetails(organizationIdentity);
    }, [organizationIdentity]);
    useEffect(() => {
        initializeOrganization();
    }, []);
    const fetchDetails = (identity) => {
        setIsLoading(true);
        organization
            .fetchDetails(identity)
            .then((response) => {
            setOrganizationDetails(response);
        })
            .finally(() => setIsLoading(false));
    };
    const updateFacility = (facilityIdentity, updatedFacility) => __awaiter(void 0, void 0, void 0, function* () {
        var _a, _b;
        if (!organizationDetails) {
            return;
        }
        const site = (_a = organizationDetails.sites) === null || _a === void 0 ? void 0 : _a.find((site) => {
            var _a;
            return (_a = site.facilities) === null || _a === void 0 ? void 0 : _a.find((facility) => {
                return facility.identity === facilityIdentity;
            });
        });
        const originalFacility = (_b = site === null || site === void 0 ? void 0 : site.facilities) === null || _b === void 0 ? void 0 : _b.find((facility) => {
            return facility.identity === facilityIdentity;
        });
        // Figure out best way to catch this/should this be caught in service layer instead
        if (!site || !originalFacility) {
            console.error('Facility not found', organizationDetails);
            throw new Error('Facility not found');
        }
        const updatedFacilityNew = Object.assign(Object.assign(Object.assign(Object.assign({}, originalFacility), convertIUpdateFacilityToIFacility(updatedFacility, originalFacility)), adjustPropertySize(originalFacility, updatedFacility)), { propertySize: resolvePropertySize(originalFacility, updatedFacility.propertySize) });
        organization.updateFacility(updatedFacilityNew, site).then(() => {
            var _a;
            const updatedSites = (_a = organizationDetails.sites) === null || _a === void 0 ? void 0 : _a.map((site) => {
                var _a;
                if (site.identity === site.identity) {
                    return Object.assign(Object.assign({}, site), { facilities: (_a = site.facilities) === null || _a === void 0 ? void 0 : _a.map((facility) => {
                            if (facility.identity === facilityIdentity) {
                                return Object.assign({}, updatedFacilityNew);
                            }
                            return facility;
                        }) });
                }
                return site;
            });
            setOrganizationDetails((prev) => prev
                ? Object.assign(Object.assign({}, prev), { sites: updatedSites }) : undefined);
        });
    });
    const upsertOrganization = (org, image, logoName, orgIdentity) => __awaiter(void 0, void 0, void 0, function* () {
        setIsLoading(true);
        const convertedOrg = convertOrganizationFormToIUpsertOrganization(org, image, logoName);
        const updatedOrg = organization
            .update(convertedOrg, orgIdentity)
            .then((response) => {
            setOrganizationDetails(response);
            onUpdateOrganization && onUpdateOrganization(response);
            return response;
        })
            .finally(() => {
            setIsLoading(false);
        });
        return updatedOrg;
    });
    const archiveOrganization = (orgToArchive) => __awaiter(void 0, void 0, void 0, function* () {
        setIsLoading(true);
        organization
            .archiveOrganization(orgToArchive)
            .then((response) => {
            onArchiveOrganization && onArchiveOrganization(orgToArchive);
            return response;
        })
            .finally(() => {
            setIsLoading(false);
        });
    });
    const initializeOrganization = () => __awaiter(void 0, void 0, void 0, function* () {
        setIsTemplateLoading(true);
        yield organization
            .initializeOrganization()
            .then((res) => {
            setOrgTemplate(res);
        })
            .catch(() => { })
            .finally(() => {
            setIsTemplateLoading(false);
        });
    });
    return {
        fetch: fetchDetails,
        isLoading,
        isTemplateLoading,
        organizationDetails,
        updateFacility,
        upsertOrganization,
        archiveOrganization,
        orgTemplate,
    };
};
