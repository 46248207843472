import { jsx as _jsx } from "react/jsx-runtime";
import { Box, Tooltip, useBreakpointValue } from '@chakra-ui/react';
import { Table } from '@frontend/design-system/components/Table/Table';
import { formatBudgetToString, } from '@frontend/domain/models/Projects/ProjectsInterfaces';
import { createColumnHelper } from '@tanstack/react-table';
import React, { useEffect } from 'react';
import { NeedsAction } from '../../components/alerts/NeedsAction';
import { ProjectFilterHeader } from '../../components/projects/ProjectFilterHeader';
import { appColors } from '../../config/constants';
import { getFriendlyPriorityRating, getPriorityInfo, } from '../../config/CPHelperFunctions';
import { projectSummaryFilter } from '../../contexts/Projects/ProjectFilterHook';
import { ProjectCard } from './ProjectCard';
import { opacityHex } from '@frontend/design-system/theme/theme';
const ProjectsTable = ({ projects, newProjectAdded, toggleNewProjectAdded, canEdit, orgIdentity, }) => {
    var _a;
    useEffect(() => {
        projectFilterContext.resetFilterItems(projects);
    }, [projects]);
    const projectFilterContext = projectSummaryFilter(projects);
    const columnHelper = createColumnHelper();
    const defaultWidths = {
        name: '198px',
        projTypeName: '154px',
        estBudget: '115px',
        facilityName: '154px',
    };
    const columnWidth = (_a = useBreakpointValue({
        base: defaultWidths,
    })) !== null && _a !== void 0 ? _a : defaultWidths;
    const columns = [
        columnHelper.accessor('prioritizationRating', {}),
        columnHelper.accessor('createdOn', {}),
        columnHelper.accessor('name', {
            cell: (info) => {
                const { name } = info.row.original;
                return (_jsx(Tooltip, Object.assign({ label: name, placement: 'bottom-start', openDelay: 750 }, { children: _jsx(Box, Object.assign({ width: columnWidth['name'], textOverflow: 'ellipsis', overflow: 'hidden' }, { children: name })) })));
            },
            header: 'Project name',
        }),
        columnHelper.accessor('projTypeName', {
            cell: (info) => {
                const { projTypeName } = info.row.original;
                return (_jsx(Tooltip, Object.assign({ label: projTypeName, openDelay: 750, placement: 'bottom-start' }, { children: _jsx(Box, Object.assign({ width: columnWidth['projTypeName'], textOverflow: 'ellipsis', overflow: 'hidden' }, { children: projTypeName })) })));
            },
            header: 'Project type',
        }),
        columnHelper.accessor('estBudget', {
            cell: (info) => {
                const { estBudget } = info.row.original;
                const formattedBudget = formatBudgetToString(estBudget);
                return (_jsx(Tooltip, Object.assign({ label: formattedBudget }, { children: _jsx(Box, Object.assign({ width: columnWidth['estBudget'], textOverflow: 'ellipsis', overflow: 'hidden' }, { children: formattedBudget })) })));
            },
            header: 'Estimated budget',
            meta: {
                isNumeric: true,
            },
        }),
        columnHelper.accessor('facilityName', {
            cell: (info) => {
                const { facilityName } = info.row.original;
                return (_jsx(Tooltip, Object.assign({ label: facilityName, openDelay: 750, placement: 'bottom-start' }, { children: _jsx(Box, Object.assign({ width: columnWidth['facilityName'], textOverflow: 'ellipsis', overflow: 'hidden' }, { children: facilityName })) })));
            },
            header: 'Facility name',
        }),
        columnHelper.accessor('prioritizationScore', {
            cell: (info) => {
                const { prioritizationScore, prioritizationRating } = info.row.original;
                return renderPriorityAverage(prioritizationScore, prioritizationRating);
            },
            header: 'Prioritization score',
            sortUndefined: -1,
        }),
    ];
    const renderPriorityAverage = (prioritizationScore, prioritizationRating) => {
        const priorInfo = getPriorityInfo(prioritizationScore);
        const friendlyRating = prioritizationRating
            ? getFriendlyPriorityRating(prioritizationRating)
            : undefined;
        const showNeedsAction = prioritizationScore === undefined || !friendlyRating;
        return (_jsx(Box, Object.assign({ bgColor: (prioritizationScore === null || prioritizationScore === void 0 ? void 0 : prioritizationScore.toString())
                ? `${priorInfo.color}${opacityHex.thirty}`
                : 'transparent', fontWeight: 500, p: '7px 15px', width: '144px' }, { children: showNeedsAction ? (_jsx(NeedsAction, { label: 'Needs Action' })) : (`${prioritizationScore} - ${friendlyRating}`) })));
    };
    const mapToProjectTable = (project) => {
        return {
            projectIdentity: project.identity,
            name: project.name,
            createdOn: project.createdOn,
            isLocked: project.isLocked,
            projTypeName: project.projectTypeName,
            fundingTypeName: project.fundingTypeName,
            facilityName: project.facilityName,
            estBudget: project.estimatedBudget,
            projScope: project.scope,
            prioritizationScore: project.priorityScore,
            prioritizationRating: project.priorityRating,
        };
    };
    const tableData = projectFilterContext.filteredItems.map((project, i) => {
        const projectTable = mapToProjectTable(project);
        const bgColor = project.isLocked ? appColors.TERCIARY_LIGHT_GRAY : '';
        return Object.assign(Object.assign({}, projectTable), { style: {
                backgroundColor: bgColor,
            }, seeMore: (_jsx(ProjectCard, { project: project, index: i, canEdit: canEdit, orgIdentity: orgIdentity }, `${project.identity} ${i}`)) });
    });
    const customOnSortingChange = () => {
        toggleNewProjectAdded(false);
    };
    return (_jsx(Table, { maxHeight: '85vh', data: tableData, columns: columns, header: _jsx(ProjectFilterHeader, { allProjects: projects, filterContext: projectFilterContext }), paddingX: '40px', initialSort: [{ id: 'prioritizationScore', desc: true }], manualSort: newProjectAdded ? [{ id: 'createdOn', desc: true }] : undefined, columnVisibility: { prioritizationRating: false, createdOn: false }, customOnSortingChange: customOnSortingChange }));
};
export default ProjectsTable;
