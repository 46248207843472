import { IAppNames } from '../App';
import { convertOrgContactToIUserContact } from '../User/UserConversions';
export const convertOrganizationSummaryResponseToOrganizationSummary = (org) => {
    const appNames = Object.values(IAppNames);
    return {
        identity: org.organizationIdentity,
        name: org.name,
        primaryContact: convertOrgContactToIUserContact(org.primaryContact),
        apps: appNames.map((app) => convertAppToIApp(app, org.apps)),
    };
};
export const convertOrganizationResponseToIOrganizationDetails = (org) => {
    const appNames = Object.values(IAppNames);
    return {
        identity: org.organizationIdentity,
        name: org.name,
        businessUnit: org.businessUnit,
        companyWebsite: org.companyWebsite,
        logo: org.logo
            ? convertOrganizationLogoResponseToIOrganizationLogo(org.logo)
            : undefined,
        verticalMarket: org.verticalMarket,
        primaryContact: convertOrgContactToIUserContact(org.primaryContact),
        apps: appNames.map((app) => convertAppToIApp(app, org.apps)),
        sites: org.sites.map((site) => convertSiteResponseToISite(site)),
        userCount: org.userCount,
    };
};
export const convertAppToIApp = (app, apps) => {
    const currentApp = apps.find((a) => a.app === app);
    const expiresOnDate = (currentApp === null || currentApp === void 0 ? void 0 : currentApp.expiresOn)
        ? currentApp.expiresOn
        : undefined;
    return {
        app: app,
        enabled: (currentApp === null || currentApp === void 0 ? void 0 : currentApp.isActive) || false,
        expiresOn: expiresOnDate,
    };
};
export const convertOrganizationFormToIUpsertOrganization = (org, image, logoName) => {
    return {
        nameOfOrg: org.nameOfOrg,
        logoFile: image,
        logoName: logoName,
        businessUnit: org.businessUnit,
        verticalMarket: org.verticalMarket,
        companyWebsite: org.companyWebsite,
        primaryContactId: org.primaryContactId,
        apps: org.apps,
    };
};
export const convertOrganizationToRequest = (org, orgIdentity) => {
    const formRequest = {
        OrganizationId: orgIdentity,
        Name: org.nameOfOrg,
        BusinessUnit: org.businessUnit,
        CompanyWebsite: org.companyWebsite,
        'Logo.LogoFile': org.logoFile,
        'Logo.FileName': org.logoName,
        VerticalMarket: org.verticalMarket,
        PrimaryContactId: org.primaryContactId,
    };
    org.apps.forEach((app, index) => {
        const appRequest = convertIAppToOrganizationAppRequest(app);
        formRequest[`Apps[${index}].app`] = appRequest.app;
        formRequest[`Apps[${index}].isActive`] = appRequest.isActive;
        formRequest[`Apps[${index}].expiresOn`] = appRequest.expiresOn;
    });
    return formRequest;
};
const convertIAppToOrganizationAppRequest = (app) => {
    return {
        app: app.app,
        isActive: app.enabled,
        expiresOn: app.expiresOn !== '' ? app.expiresOn : undefined,
    };
};
// This will be moved to a site specific interface file once the service is split
export const convertSiteResponseToISite = (site) => {
    var _a;
    return {
        id: site.siteId,
        identity: site.siteIdentity,
        name: site.name,
        address1: site.address1,
        address2: (_a = site.address2) !== null && _a !== void 0 ? _a : undefined,
        stateOrProvince: site.stateOrProvince,
        postalCode: site.postalCode,
        facilities: site.facilities.map((facility) => convertFacilityResponseToIFacility(facility)),
    };
};
export const convertFacilityResponseToIFacility = (facility) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q;
    return ({
        id: facility.facilityId,
        identity: facility.facilityIdentity,
        name: facility.name,
        address1: facility.address1,
        address2: (_a = facility.address2) !== null && _a !== void 0 ? _a : undefined,
        city: facility.city,
        stateOrProvince: facility.stateOrProvince,
        postalCode: facility.postalCode,
        latitude: (_b = facility.latitude) !== null && _b !== void 0 ? _b : undefined,
        longitude: (_c = facility.longitude) !== null && _c !== void 0 ? _c : undefined,
        yearBuilt: (_d = facility.yearBuilt) !== null && _d !== void 0 ? _d : undefined,
        originalSquareFootage: (_e = facility.originalSquareFootage) !== null && _e !== void 0 ? _e : undefined,
        note: (_f = facility.note) !== null && _f !== void 0 ? _f : undefined,
        numberOfOccupants: (_g = facility.numberOfOccupants) !== null && _g !== void 0 ? _g : undefined,
        primaryFacilityType: facility.primaryFacilityType,
        secondaryFacilityType: (_h = facility.secondaryFacilityType) !== null && _h !== void 0 ? _h : undefined,
        tertiaryFacilityType: (_j = facility.tertiaryFacilityType) !== null && _j !== void 0 ? _j : undefined,
        keyCount: (_k = facility.keyCount) !== null && _k !== void 0 ? _k : undefined,
        unitCount: (_l = facility.unitCount) !== null && _l !== void 0 ? _l : undefined,
        propertySize: resolvePropertySizeType(facility),
        customerInternalId: (_m = facility.customerInternalId) !== null && _m !== void 0 ? _m : undefined,
        propertyManager: (_o = facility.propertyManager) !== null && _o !== void 0 ? _o : undefined,
        hasPropertySurvey: facility.hasPropertySurvey ? 'Yes' : 'No',
        hasCompletedAudit: facility.hasCompletedAudit ? 'Yes' : 'No',
        facilityGrouper1: (_p = facility.facilityGrouper1) !== null && _p !== void 0 ? _p : undefined,
        facilityGrouper2: (_q = facility.facilityGrouper2) !== null && _q !== void 0 ? _q : undefined,
        additions: facility.additions.map((addition) => convertFacilityAdditionResponseToIFacilityAddition(addition)),
    });
};
export const convertFacilityAdditionResponseToIFacilityAddition = (addition) => {
    return {
        id: addition.additionId,
        identity: addition.additionIdentity,
        name: addition.name,
        yearBuilt: addition.yearBuilt,
        squareFootage: addition.squareFootage,
    };
};
export const convertIUpdateFacilityToIFacility = (facility, originalFacility) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t;
    return Object.assign(Object.assign({}, originalFacility), { name: (_a = facility.name) !== null && _a !== void 0 ? _a : originalFacility.name, address1: (_b = facility.address1) !== null && _b !== void 0 ? _b : originalFacility.address1, address2: (_c = facility.address2) !== null && _c !== void 0 ? _c : originalFacility.address2, city: (_d = facility.city) !== null && _d !== void 0 ? _d : originalFacility.city, stateOrProvince: (_e = facility.stateOrProvince) !== null && _e !== void 0 ? _e : originalFacility.stateOrProvince, postalCode: (_f = facility.postalCode) !== null && _f !== void 0 ? _f : originalFacility.postalCode, yearBuilt: (_g = facility.yearBuilt) !== null && _g !== void 0 ? _g : originalFacility.yearBuilt, note: (_h = facility.note) !== null && _h !== void 0 ? _h : originalFacility.note, numberOfOccupants: (_j = facility.numberOfOccupants) !== null && _j !== void 0 ? _j : originalFacility.numberOfOccupants, primaryFacilityType: (_k = facility.primaryFacilityType) !== null && _k !== void 0 ? _k : originalFacility.primaryFacilityType, secondaryFacilityType: (_l = facility.secondaryFacilityType) !== null && _l !== void 0 ? _l : originalFacility.secondaryFacilityType, tertiaryFacilityType: (_m = facility.tertiaryFacilityType) !== null && _m !== void 0 ? _m : originalFacility.tertiaryFacilityType, customerInternalId: (_o = facility.customerInternalId) !== null && _o !== void 0 ? _o : originalFacility.customerInternalId, propertyManager: (_p = facility.propertyManager) !== null && _p !== void 0 ? _p : originalFacility.propertyManager, hasPropertySurvey: ((_q = facility.hasPropertySurvey) !== null && _q !== void 0 ? _q : originalFacility.hasPropertySurvey)
            ? 'Yes'
            : 'No', hasCompletedAudit: ((_r = facility.hasCompletedAudit) !== null && _r !== void 0 ? _r : originalFacility.hasCompletedAudit)
            ? 'Yes'
            : 'No', facilityGrouper1: (_s = facility.facilityGrouper1) !== null && _s !== void 0 ? _s : originalFacility.facilityGrouper1, facilityGrouper2: (_t = facility.facilityGrouper2) !== null && _t !== void 0 ? _t : originalFacility.facilityGrouper2, additions: [] });
};
export const mapToFacilityRequest = (facility) => {
    return {
        facilityId: facility.identity,
        name: facility.name,
        address1: facility.address1,
        address2: facility.address2,
        city: facility.city,
        stateOrProvince: facility.stateOrProvince,
        postalCode: facility.postalCode,
        latitude: facility.latitude,
        longitude: facility.longitude,
        yearBuilt: facility.yearBuilt,
        originalSquareFootage: facility.originalSquareFootage,
        note: facility.note,
        numberOfOccupants: facility.numberOfOccupants,
        primaryFacilityType: facility.primaryFacilityType,
        secondaryFacilityType: facility.secondaryFacilityType,
        tertiaryFacilityType: facility.tertiaryFacilityType,
        customerInternalId: facility.customerInternalId,
        propertyManager: facility.propertyManager,
        hasPropertySurvey: facility.hasPropertySurvey.toString() === 'Yes',
        hasCompletedAudit: facility.hasCompletedAudit.toString() === 'Yes',
        keyCount: facility.keyCount,
        unitCount: facility.unitCount,
        facilityGrouper1: facility.facilityGrouper1,
        facilityGrouper2: facility.facilityGrouper2,
        additions: [], // this will have to be changed in future implementations of the platform endpoint
    };
};
export const adjustPropertySize = (originalFacility, updateRequest) => {
    var _a, _b, _c;
    switch (originalFacility.primaryFacilityType) {
        case 'Hospitality':
            return {
                keyCount: (_a = updateRequest.propertySize) !== null && _a !== void 0 ? _a : originalFacility.keyCount,
            };
        case 'Housing':
            return {
                unitCount: (_b = updateRequest.propertySize) !== null && _b !== void 0 ? _b : originalFacility.unitCount,
            };
        default:
            return {
                originalSquareFootage: (_c = updateRequest.propertySize) !== null && _c !== void 0 ? _c : originalFacility.originalSquareFootage,
            };
    }
};
export const resolvePropertySizeType = (facility) => {
    var _a, _b, _c;
    switch (facility.primaryFacilityType) {
        case 'Hospitality':
            return {
                value: (_a = facility.keyCount) !== null && _a !== void 0 ? _a : 0,
                type: '# Keys',
            };
        case 'Housing':
            return {
                value: (_b = facility.unitCount) !== null && _b !== void 0 ? _b : 0,
                type: '# Units',
            };
        default:
            return {
                value: (_c = facility.originalSquareFootage) !== null && _c !== void 0 ? _c : 0,
                type: 'Sq Ft',
            };
    }
};
export const resolvePropertySize = (facility, value) => {
    switch (facility.primaryFacilityType) {
        case 'Hospitality':
            return {
                value: value !== null && value !== void 0 ? value : 0,
                type: '# Keys',
            };
        case 'Housing':
            return {
                value: value !== null && value !== void 0 ? value : 0,
                type: '# Units',
            };
        default:
            return {
                value: value !== null && value !== void 0 ? value : 0,
                type: 'Sq Ft',
            };
    }
};
export const convertIOrganizationDetailsToIOrganizationSummary = (org) => {
    return {
        identity: org.identity,
        name: org.name,
        primaryContact: org.primaryContact,
        apps: org.apps,
    };
};
export const convertOrganizationLogoResponseToIOrganizationLogo = (orgLogo) => {
    return {
        name: orgLogo.logoFileName,
        url: orgLogo.logoUrl,
    };
};
export const convertOrganizationTemplateResponseToIOrganizationInitial = (orgInitial) => {
    return {
        verticalMarkets: orgInitial.verticalMarkets,
        appContexts: orgInitial.appContexts,
    };
};
