var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, Heading, HStack, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay, } from '@chakra-ui/react';
import React from 'react';
import { appColors } from '../../theme/constants';
import { variants } from '../../theme/theme';
// TODO: Switch to a warning modal that takes in the names of the buttons as well or create a design-system warning modal
export const DeleteModal = ({ disclosure, onDelete, text, isArchive, }) => {
    return (_jsxs(Modal, Object.assign({ isOpen: disclosure.isOpen, onClose: disclosure.onClose, isCentered: true }, { children: [_jsx(ModalOverlay, {}), _jsxs(ModalContent, Object.assign({ maxW: '550px', maxH: 'fit-content' }, { children: [_jsx(ModalHeader, { children: _jsx(HStack, Object.assign({ justifyContent: 'space-between' }, { children: _jsx(Heading, { as: 'h1' }) })) }), _jsxs(ModalBody, Object.assign({ overflow: 'auto' }, { children: [_jsx("p", { children: text }), _jsx("p", Object.assign({ style: { marginTop: '15px' } }, { children: "Are you sure you want to continue?" }))] })), _jsxs(ModalFooter, Object.assign({ justifyContent: 'space-between' }, { children: [_jsx(Button, Object.assign({ variant: variants.blueOutlineBtn, onClick: disclosure.onClose }, { children: "Cancel" })), _jsx(Button, Object.assign({ variant: variants.blueBtn, backgroundColor: appColors.DELETE_ERROR, onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                                    onDelete();
                                    disclosure.onClose();
                                }) }, { children: isArchive ? 'Archive' : 'Delete' }))] }))] }))] })));
};
