const textStyles = {
    headlines: {
        h1: {
            fontSize: '32px',
            fontWeight: 'regular',
            lineHeight: '52px',
        },
        h2: {
            fontSize: '24px',
            fontWeight: 'semibold',
            lineHeight: '29px',
        },
        h3: {
            fontSize: '24px',
            fontWeight: 'regular',
            lineHeight: '52px',
        },
        h4: {
            fontSize: '20px',
            fontWeight: 'semibold',
            lineHeight: '25px',
        },
        h5: {
            fontSize: '20px',
            fontWeight: 'regular',
            lineHeight: '25px',
        },
    },
    pretitle: {
        regular: {
            fontSize: '18px',
            fontWeight: 'regular',
            lineHeight: '25px',
        },
        strong: {
            fontSize: '18px',
            fontWeight: 'semibold',
            lineHeight: '25px',
        },
    },
    body: {
        regular: {
            fontSize: '14px',
            fontWeight: 'regular',
            lineHeight: '22px',
        },
        strong: {
            fontSize: '14px',
            fontWeight: 'semibold',
            lineHeight: '22px',
        },
        italic: {
            fontSize: '14px',
            fontWeight: 'regular',
            lineHeight: '22px',
            fontStyle: 'italic',
        },
    },
    small: {
        regular: {
            fontSize: '10px',
            fontWeight: 'medium',
            lineHeight: '14px',
        },
        strong: {
            fontSize: '10px',
            fontWeight: 'bold',
            lineHeight: '14px',
        },
    },
    button: { fontSize: '14px', fontWeight: 'medium', lineHeight: '20px' },
};
export default textStyles;
