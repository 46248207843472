export const appColors = {
    ASSESSED_GREEN: 'rgba(52, 168, 83, 0.25)',
    BORDER_SEC_LIGHT_GRAY: '#E6E7E9',
    PRIM_BLUE: '#003B5D',
    SEC_ORANGE: '#F6862A',
    SEC_LIGHT_GRAY: '#D6D7DB',
    SEC_DARK_GRAY: '#818584',
    TEXT_SEC_DARK_GRAY: '#2E3532',
    TERCIARY_LIGHT_GRAY: '#F2F3F4',
    DELETE_ERROR: '#DA1100',
    PRIM_LIGHT_GREY: '#C0C2C1',
};
export const appValues = {
    HEADER_HEIGHT_MAIN: 57,
    HEADER_HEIGHT: 100,
    FOOTER_HEIGHT: 45,
    SIDEBAR_WIDTH_FULL: 327,
    SIDEBAR_WIDTH_SMALL: 77,
    SIDEBAR_WIDTH_PLANS: 250,
    SIDEBAR_WIDTH_DETAILS: 288,
    TRANSITION_DURATION: '0.3s',
    SIDEBAR_ITEM_BUFFER_WIDTH: 25,
};
// used to display to users
export var FriendlyPriorityCode;
(function (FriendlyPriorityCode) {
    FriendlyPriorityCode["LONG_RANGE"] = "Long-Range";
    FriendlyPriorityCode["LOW"] = "Low";
    FriendlyPriorityCode["MODERATE"] = "Moderate";
    FriendlyPriorityCode["HIGH"] = "High";
    FriendlyPriorityCode["IMMEDIATE"] = "Immediate";
    FriendlyPriorityCode["NEEDS_ACTION"] = "Needs Action";
})(FriendlyPriorityCode || (FriendlyPriorityCode = {}));
// used to match actual data values
export var PriorityCode;
(function (PriorityCode) {
    PriorityCode["LONG_RANGE"] = "LongRange";
    PriorityCode["LOW"] = "Low";
    PriorityCode["MODERATE"] = "Moderate";
    PriorityCode["HIGH"] = "High";
    PriorityCode["IMMEDIATE"] = "Immediate";
    PriorityCode["NEEDS_ACTION"] = "NeedsAction";
})(PriorityCode || (PriorityCode = {}));
export const priorityColorsCodes = [
    {
        color: 'transparent',
        code: FriendlyPriorityCode.NEEDS_ACTION,
        enumCode: PriorityCode.NEEDS_ACTION,
    },
    {
        color: '#2E3532',
        code: FriendlyPriorityCode.LONG_RANGE,
        enumCode: PriorityCode.LONG_RANGE,
    },
    {
        color: '#818584',
        code: FriendlyPriorityCode.LOW,
        enumCode: PriorityCode.LOW,
    },
    {
        color: '#003B5D',
        code: FriendlyPriorityCode.MODERATE,
        enumCode: PriorityCode.MODERATE,
    },
    {
        color: '#FEDB5E',
        code: FriendlyPriorityCode.HIGH,
        enumCode: PriorityCode.HIGH,
    },
    {
        color: '#F6862A',
        code: FriendlyPriorityCode.IMMEDIATE,
        enumCode: PriorityCode.IMMEDIATE,
    },
];
export const measureColors = {
    MEASURE_EXCELLENT: '#003B5D',
    MEASURE_ACCEPTABLE: '#6C899E',
    MEASURE_CAUTION: '#B5C4CE',
    MEASURE_ALERT: '#F8B57C',
    MEASURE_ALARM: '#F6862A',
};
export var projectLabels;
(function (projectLabels) {
    projectLabels["projectId"] = "Project ID";
    projectLabels["projectName"] = "Project Name";
    projectLabels["projType"] = "Project Type";
    projectLabels["fundingType"] = "Default Funding Type";
    projectLabels["estBudget"] = "Estimated Budget";
    projectLabels["budgetDate"] = "Budget Date";
    projectLabels["projScope"] = "Project Scope";
    projectLabels["prioritizationScore"] = "Prioritization Score";
    projectLabels["assetGroups"] = "Asset Groups";
    projectLabels["currentIssues"] = "Current Issues";
})(projectLabels || (projectLabels = {}));
export var FiltersSorters;
(function (FiltersSorters) {
    FiltersSorters["PROJECT_NAME"] = "Project Name";
    FiltersSorters["FACILITY_NAME"] = "Facility";
    FiltersSorters["ESTIMATE_BUDGET"] = "Estimated Budget";
    FiltersSorters["PROJECT_TYPE"] = "Project Type";
    FiltersSorters["PRIORITY"] = "By Priority";
})(FiltersSorters || (FiltersSorters = {}));
export var environmentalLabelsAndTexts;
(function (environmentalLabelsAndTexts) {
    environmentalLabelsAndTexts["co2eSavingsLabel"] = "Annual CO2e Savings";
    environmentalLabelsAndTexts["co2eSavingsText"] = "tons saved for";
    environmentalLabelsAndTexts["electricitySavingsLabel"] = "Annual Electricity Savings";
    environmentalLabelsAndTexts["electricitySavingsText"] = "kWh saved for";
    environmentalLabelsAndTexts["naturalGasSavingsLabel"] = "Annual Natural Gas Savings";
    environmentalLabelsAndTexts["naturalGasSavingsText"] = "therms saved for";
    environmentalLabelsAndTexts["waterSavingsLabel"] = "Annual Water Savings";
    environmentalLabelsAndTexts["waterSavingsText"] = "gallons saved for";
    environmentalLabelsAndTexts["years"] = "years";
})(environmentalLabelsAndTexts || (environmentalLabelsAndTexts = {}));
