var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { HStack, Text, useDisclosure, VStack } from '@chakra-ui/react';
import { FileDownloadButton } from '@frontend/design-system/components/Buttons/FileDownloadButton';
import { Form } from '@frontend/design-system/components/FormModal/FormModal';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { convertITaxImpactDetailsToCreateTaxImpactForm } from '../../../contexts/Scenarios/ScenarioConversions';
import { initialCreateTaxImpactData, } from '../../../objects/CPCreateUpdateInterfaces';
import { FundContributionSection } from './FundContributionSection';
import { GeneralInformationSection } from './GeneralInformationSection';
import { StateAidAssociatedSection } from './StateAidAssociatedSection';
import { TaxImpactSection } from './TaxImpactSection';
import { TaxImpactWarningModal } from './TaxImpactWarningModal';
export const TaxImpactModal = ({ disclosure, taxImpact, onCreate, fetchTaxImpactCSV, canManageScenario, canExportProjects, isTaxImpactDownloading, }) => {
    const taxImpactForm = useForm({
        values: convertITaxImpactDetailsToCreateTaxImpactForm(taxImpact),
        mode: 'onChange',
    });
    const { formState: { isValid }, handleSubmit, reset, } = taxImpactForm;
    const taxImpactWarningDisclosure = useDisclosure();
    const resetForm = () => {
        reset(initialCreateTaxImpactData);
    };
    useEffect(() => {
        if (disclosure.isOpen && taxImpact === undefined) {
            // has to reset on open to properly handle submit form and close modal events
            resetForm();
        }
    }, [disclosure.isOpen]);
    const submitTaxImpact = (data) => __awaiter(void 0, void 0, void 0, function* () {
        taxImpactWarningDisclosure.onClose();
        return onCreate(data);
    });
    const downloadCsvFile = () => __awaiter(void 0, void 0, void 0, function* () {
        return yield fetchTaxImpactCSV();
    });
    const formTitle = 'Tax Impact';
    return (_jsx(Form.Modal, Object.assign({ disclosure: disclosure, onApply: handleSubmit(submitTaxImpact), onClear: () => {
            disclosure.onClose();
        }, size: '4xl', labels: {
            title: formTitle,
            apply: 'Save',
            cancel: 'Cancel',
        }, applyDisabled: !isValid, showButtons: taxImpact
            ? { apply: false, cancel: false, exit: true }
            : { apply: true, cancel: true, exit: true }, submitOverride: taxImpactWarningDisclosure.onOpen }, { children: _jsxs(VStack, Object.assign({ w: '100%', px: '7px', pb: taxImpact ? '25px' : undefined }, { children: [taxImpact && canManageScenario && (_jsx(Text, Object.assign({ "aria-label": 'edit-instructions' }, { children: "To edit this information, you need to unlock the scenario and re-lock it. Unlocking a scenario removes all tax impact information." }))), canExportProjects && (_jsxs(HStack, { children: [_jsx(Text, Object.assign({ "aria-label": 'download-instructions' }, { children: "For more reference, you can download a CSV with all the projects\u2019 information." })), _jsx(FileDownloadButton, { fetchFile: downloadCsvFile, buttonName: 'Download CSV', isDisabled: isTaxImpactDownloading })] })), !taxImpact && (_jsx(Text, Object.assign({ alignSelf: 'start', fontWeight: '500', fontSize: '10px', textColor: 'crimson' }, { children: "(*) Required fields" }))), _jsxs(HStack, Object.assign({ w: '100%', justifyContent: 'space-between' }, { children: [_jsxs(VStack, Object.assign({ alignSelf: 'start' }, { children: [_jsx(GeneralInformationSection, { formContext: taxImpactForm, taxImpact: taxImpact }), _jsx(TaxImpactSection, { formContext: taxImpactForm, taxImpactExists: taxImpact !== undefined })] })), _jsxs(VStack, Object.assign({ alignSelf: 'start' }, { children: [_jsx(FundContributionSection, { formContext: taxImpactForm, taxImpact: taxImpact }), _jsx(StateAidAssociatedSection, { formContext: taxImpactForm, taxImpact: taxImpact })] }))] })), _jsx(TaxImpactWarningModal, { formName: `${formTitle}_form`, isOpen: taxImpactWarningDisclosure.isOpen, onClose: taxImpactWarningDisclosure.onClose })] })) })));
};
