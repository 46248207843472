import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Flex, useBreakpointValue } from '@chakra-ui/react';
import { useAppAccess } from '@frontend/domain/contexts/Authorization/UserAccess';
import { useOrganizationSummaries } from '@frontend/domain/contexts/Organization/OrganizationSummaryContext';
import { useCurrentUser } from '@frontend/domain/contexts/User/UserContext';
import { useReportList } from '@frontend/domain/hooks/Report/ReportListHook';
import { IAppNames } from '@frontend/domain/models/App';
import { useEffect } from 'react';
import { ExpandableSidebar } from '../navigation/ExpandableSidebar';
import { PageHeader, } from '../navigation/PageHeader';
export const PageLayout = ({ title, onAddScenario, onAddProject, canAddProject, canAddScenario, children, canEdit = false, }) => {
    var _a;
    const { selectedOrganizationIdentity, organizations, isLoading, onSelectOrganization, } = useOrganizationSummaries();
    const { user } = useCurrentUser();
    const { hasAccess } = useAppAccess(IAppNames.PLANNER);
    const { fetch, reports } = useReportList();
    useEffect(() => {
        if (selectedOrganizationIdentity) {
            fetch(selectedOrganizationIdentity);
        }
    }, [selectedOrganizationIdentity]);
    const getHeaderButtonConfig = () => {
        if (!hasAccess ||
            !selectedOrganizationIdentity ||
            !canEdit ||
            !onAddProject ||
            !onAddScenario) {
            return undefined;
        }
        else {
            return {
                projectButtonConfig: {
                    label: 'Project',
                    onClick: () => onAddProject(selectedOrganizationIdentity),
                    isDisabled: !canAddProject,
                },
                scenarioButtonConfig: {
                    label: 'Scenario',
                    onClick: () => onAddScenario(selectedOrganizationIdentity),
                    isDisabled: !canAddScenario,
                },
            };
        }
    };
    const dropdownLabel = (_a = useBreakpointValue({
        base: 'Org',
        lg: 'Organization',
    })) !== null && _a !== void 0 ? _a : 'Organization';
    return (_jsx(ExpandableSidebar, Object.assign({ user: user, orgIdentity: selectedOrganizationIdentity, reports: reports }, { children: _jsxs(_Fragment, { children: [_jsx(PageHeader, { title: title || '', addButtonConfig: getHeaderButtonConfig(), orgIdentity: selectedOrganizationIdentity, organizations: organizations, isLoading: isLoading, onSelectOrganization: onSelectOrganization, dropdownLabel: dropdownLabel }), _jsx(Flex, Object.assign({ flexGrow: 1, flexDir: 'column', p: 10 }, { children: children }))] }) })));
};
